import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const ErrorSection = styled.section`
  padding-top: 30px;
  background-color: #efefef;
`

const ErrorWrapper = styled.div`
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  padding-bottom: 1rem;

  @media only screen and (min-width: 75em) {
    margin: auto;
    max-width: 85.2rem;
  }

  @media only screen and (min-width: 62em) {
    margin: auto;
    max-width: 73.2rem;
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <ErrorSection>
      <ErrorWrapper>
        <h1 style={{ textAlign: "center"}}>404 - NOT FOUND</h1>
        <p style={{textAlign: "center"}}>You just hit a route that doesn&#39;t exist...</p>
      </ErrorWrapper>
    </ErrorSection>
  </Layout>
)

export default NotFoundPage
